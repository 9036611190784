html {
    font-size: $html-font-size;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;

    @media (max-width: 991px) {
        font-size: rem($html-font-size * .8);
    }
}

body {
    margin: 0;
    padding: 0;
    text-align: left;
    line-height: inherit;
    background: $body-bg;
    font-family: $ffText;
    color: $colorText;
    font-size: rem($fzText);
    --swiper-theme-color: #{$swiperThemeColor};
    --swiper-theme-inactive-color: #{$swiperThemeInactiveColor};
}

.hidden {
    display: none;
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: $widthContainer;
    }
}
@include respond-to($semi-desktop1){
    .container {
        max-width: $widthContainer-lg;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: rem(20px) 0;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $ffTitle;
    color: $colorTitle;
}

$listStyles: ("h1": $fzH1, "h2": $fzH2, "h3": $fzH3, "h4": $fzH4, "h5": $fzH5, "h6": $fzH6);

@each $element, $size in $listStyles {
    #{$element} {
        font-size: rem($size);
    }
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="week"],
input[type="month"],
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="number"],
optgroup,
select,
textarea,
.form-control {
  border: 1px solid gainsboro;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  background-color: #fff;
}

.form-control:focus {
    border-color: rgba($primary, .25);
    box-shadow: 0 0 0 0.2rem rgba($primary, .25);
}

ul, ol {
    margin: 10px;
    padding: 0;
    list-style-position: inside;
}

a {
    transition: all 0.2s linear;
    color: $colorLink;

    &:hover {
        text-decoration: none;
        color: $primary;
    }

    &:focus {
        outline: 0;
    }

    img {
        border: 0;
    }

    // &[href$=".pdf"] {
    //     padding: 10px 0 10px 30px;
    //     background: url(/img/pdf.png) no-repeat 0 50% transparent;
    // }
}

blockquote {
    margin: 30px 0;
    padding: 5px 15px;
    border-left: 4px solid #eee;
    font-style: italic;
}

em {
    font-style: italic;
}

strong {
    font-weight: 700;
}

.overflow {
    overflow: hidden;
}

#scrollToTop {
    position: fixed; bottom: 10px; right: 10px; cursor: pointer; z-index: 1000000; display: none; 
    border-radius: 50%;
    background: $primaryLight;
}

ol.breadcrumb {
    margin: 20px 0;
}

.text-main {
    @include text-emphasis($main);
}.text-default {
    @include text-emphasis($default);
}.text-primary {
    @include text-emphasis($primary);
}.text-secondary {
    @include text-emphasis($secondary);
}
.bg-main, .badge-main {
    background-color: $main !important;
}.bg-primary, .badge-primary {
    background-color: $primary !important;
}.bg-default, .badge-default {
    background-color: $default !important;
    color: $main;
}.bg-secondary, .badge-secondary {
    background-color: $secondary !important;
}.bg-light, .badge-light {
    background-color: $light !important;
}

.shadow {
    box-shadow: $shadow;
}


/* #CONTENT
================================================== */

#content {
    //padding-bottom: 20px;
    min-height: 60vh;
}

#page img { 
    @extend %img-fluid;
}

.fancybox-slide--current #hidden-content-b {
    transform: translateY(0);
}

/* ----- NOTIFICATIONS -----*/

.result_error {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #e57c7c;
}

.result_success {
    color: #fff;
    line-height: 40px;
    text-align: center;
    background: #78ca71;
}

#flashMessage {
    background: #fff;
    color: #337ab7;
    line-height: 40px;
    text-align: center;
}

// Connexion
#tableau_login { overflow:hidden; }

#compte { margin: 20px auto}

#UserSiteLoginForm div, #UserAddUserForm div { margin: 0 0 10px; }

.error-message { color: #a94442}

// Contact
.accept {
    display: inline;
}

.error {
    .form-control {
        border-color: $danger;
    }
    .control-label {
        color: $danger;
    }
}

.mandatory {
    height: 0.1px;
    width: 0;
    border: 0 !important;
    padding: 0 !important;
}

.mentionRGPD {
    font-size: rem(14px);
}

#map {
    background: $gray-200;
    width: 100%;
    height: 880px;
    color: #000;
    overflow: hidden;
    .googlemaps-canvas {
        max-width: 100%;    
    }
}

/* video iframe responsive */
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* ----- DEMO ASSETS ----- */

.bs-component {
    .modal {
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        bottom: auto;
        z-index: 1;
        display: block;
    }

    .modal-dialog {
        width: 90%;
    }
}

.modal-title {
    margin-top: 0;
}

/* ----- Message de consentement RGPD ----- */

.mentionRGPD {
    font-size: 0.8em;
}

// pagination
.pagination {
    justify-content: center;
    border-radius: 0;
    .page-link {
        padding: 0.25rem 0.75rem;
        background-color: $main;
        border: 0;
        color: $default;
        border-radius: 4px !important;
        text-decoration: none;
        &:hover {
            background: $mainDark;
        }
        &:focus, &:active {
            box-shadow: none;    
        }
    }
    .page-item.active {
        .page-link {
            color: $main;
            background-color: $primary;
        }

        &:focus {
            box-shadow: none;
        }
    }
    .page-item.disabled {
        background-color: $mainLight;
        .page-link {
            background: transparent;
        }
    }
}

img {
    &[class^="align"] {
        max-width: 100%;
        height: auto;

        @media (max-width: 767px) {
            max-width: 100%;
            margin-bottom: 30px;
            margin-top: 30px;
            float: none;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &.alignright {
        max-width: calc(100% - 15px);
        float: right;
        margin-left: 30px;

        + figcaption {
            text-align: right;
            clear: both;
        }
    }

    &.alignleft {
        max-width: calc(100% - 15px);
        float: left;
        margin-right: 30px;
    }

    &.aligncenter {
        max-width: calc(100% - 30px);
        display: block;
        margin: 15px auto;

        + figcaption {
            text-align: center;
        }
    }
}

.googlemaps-canvas, .googlemaps-canvas > div {
    max-width: 100%;
}

/* No longer used 
 * see below, Imperavi style */
/*.coralGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gap, 30px);

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &-item {
        grid-column-end: span var(--sizecolumnrounded);

        @media screen and (max-width: 767px) {
            grid-column-end: span 1;
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }
}
/* Imperavi style */
.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px; }

.grid + .grid {
  margin-top: 24px; }

.grid-gutterless {
  grid-column-gap: 0; }

.grid-gapless {
  grid-row-gap: 0; }
  .grid-gapless + .grid {
    margin-top: 0; }

.grid-auto {
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr)); }

.grid-1 {
  grid-template-columns: repeat(1, 1fr); }

.grid-2 {
  grid-template-columns: repeat(2, 1fr); }

.grid-3 {
  grid-template-columns: repeat(3, 1fr); }

.grid-4 {
  grid-template-columns: repeat(4, 1fr); }

.grid-5 {
  grid-template-columns: repeat(5, 1fr); }

.grid-6 {
  grid-template-columns: repeat(6, 1fr); }

.grid-7 {
  grid-template-columns: repeat(7, 1fr); }

.grid-8 {
  grid-template-columns: repeat(8, 1fr); }

.grid-9 {
  grid-template-columns: repeat(9, 1fr); }

.grid-10 {
  grid-template-columns: repeat(10, 1fr); }

.grid-11 {
  grid-template-columns: repeat(11, 1fr); }

.grid-12 {
  grid-template-columns: repeat(12, 1fr); }

.column-1 {
  grid-column-end: span 1; }

.column-2 {
  grid-column-end: span 2; }

.column-3 {
  grid-column-end: span 3; }

.column-4 {
  grid-column-end: span 4; }

.column-5 {
  grid-column-end: span 5; }

.column-6 {
  grid-column-end: span 6; }

.column-7 {
  grid-column-end: span 7; }

.column-8 {
  grid-column-end: span 8; }

.column-9 {
  grid-column-end: span 9; }

.column-10 {
  grid-column-end: span 10; }

.column-11 {
  grid-column-end: span 11; }

.column-12 {
  grid-column-end: span 12; }

@media only screen and (max-width: 1023px) {
  .grid-1-md {
    grid-template-columns: repeat(1, 1fr); }
  .grid-2-md {
    grid-template-columns: repeat(2, 1fr); }
  .grid-3-md {
    grid-template-columns: repeat(3, 1fr); }
  .grid-4-md {
    grid-template-columns: repeat(4, 1fr); }
  .grid-5-md {
    grid-template-columns: repeat(5, 1fr); }
  .grid-6-md {
    grid-template-columns: repeat(6, 1fr); }
  .grid-7-md {
    grid-template-columns: repeat(7, 1fr); }
  .grid-8-md {
    grid-template-columns: repeat(8, 1fr); }
  .grid-9-md {
    grid-template-columns: repeat(9, 1fr); }
  .grid-10-md {
    grid-template-columns: repeat(10, 1fr); }
  .grid-11-md {
    grid-template-columns: repeat(11, 1fr); }
  .grid-12-md {
    grid-template-columns: repeat(12, 1fr); } }

@media only screen and (max-width: 991px) {
  .grid,
  .grid-auto {
    grid-template-columns: repeat(1, 1fr); }
  .column {
    grid-column-end: span 1; }
  .column:empty {
    display: none; }
  .grid-1-sm {
    grid-template-columns: repeat(1, 1fr); }
  .grid-2-sm {
    grid-template-columns: repeat(2, 1fr); }
  .grid-3-sm {
    grid-template-columns: repeat(3, 1fr); }
  .grid-4-sm {
    grid-template-columns: repeat(4, 1fr); }
  .grid-5-sm {
    grid-template-columns: repeat(5, 1fr); }
  .grid-6-sm {
    grid-template-columns: repeat(6, 1fr); }
  .grid-7-sm {
    grid-template-columns: repeat(7, 1fr); }
  .grid-8-sm {
    grid-template-columns: repeat(8, 1fr); }
  .grid-9-sm {
    grid-template-columns: repeat(9, 1fr); }
  .grid-10-sm {
    grid-template-columns: repeat(10, 1fr); }
  .grid-11-sm {
    grid-template-columns: repeat(11, 1fr); }
  .grid-12-sm {
    grid-template-columns: repeat(12, 1fr); }
}
figure > img,
figure > iframe,
figure > pre,
figure > div {
  vertical-align: middle; }

figure figcaption {
  display: block;
  font-style: normal;
  margin-top: 0.25em;
  font-size: 12px;
  line-height: 1.5; }
.align-left {
  text-align: left; }

.align-center {
  text-align: center; }
  .align-center img {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%; }
  .align-center figcaption {
    text-align: center;
    margin-left: auto;
    margin-right: auto; }

.align-right {
  text-align: right; }
  .align-right img {
    margin-left: auto; }
  .align-right figcaption {
    text-align: right;
    margin-left: auto; }

.align-justify {
  text-align: justify; }
.valign-top {
  align-items: start; }

.valign-middle {
  align-items: center; }

.valign-bottom {
  align-items: end; }

.outset-right {
  position: relative;
  width: calc(100% + 24px);
  max-width: calc(100% + 24px);
  transform: translateX(24px) translate3d(0, 0, 0);
  left: calc(24px * -1); }

.outset-left {
  width: calc(100% + 24px);
  max-width: calc(100% + 24px);
  transform: translateX(calc(24px * -1)) translate3d(0, 0, 0); }

.outset-both {
  position: relative;
  width: calc(100% + 24px + 24px);
  max-width: calc(100% + 24px + 24px);
  transform: translateX(-50%) translate3d(0, 0, 0);
  left: 50%; }
/* END Imperavi */