@import '../modules/recrutement/_recrutement-annonce';

.recrutement {
    
    &-widget {
        .table thead th {
            vertical-align: middle;
        }
    }
    
    &-annonce {

    }

    &-title {

    }
    
    &-date {
        width: 15%;
    }
    
    &-link {
        width: 15%;
        text-align: center;
    }
}
