.header {
    background: $bgcHeader;
    //padding: 20px 0;
}

.topBar {
    background: $default;
    color: $main;
    display: flex;
    flex-wrap: wrap;
    line-height: 3em;
    justify-content: space-between;
    
    &__links {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        column-gap: 1rem;
    }
    
    a {
        color: $main;
        &:hover {
            color: $colorDiscrete;
        }
        &.disabled {
            color: $gray-500;
            cursor: not-allowed;
        }
    }
}

.logo {
    display: inline-block;
    img {
        max-width: 100%;
        height: auto;
    }
    @media(max-width: 991px){
        margin: 1.5rem auto 0;
        width: 100%;
        text-align: center;
    }
}


@include respond-to($phone-portrait){
}@include respond-to($phone-landscape){
}@include respond-to($tablet-portrait){
}@include respond-to($tablet-landscape){
}@include respond-to($semi-desktop1){
}@include respond-to($semi-desktop2){
}@include respond-to($wide-desktop){
}