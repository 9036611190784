/**
 * single auto in loop
 */

.webauto {
    $parent: &;
    
    &-loop {
        
    }
    &-fiche {
    
    }
    &-ficheInner {
        transition: all, .2s;    
    } 
    &-image {
        transition: opacity .2s;
        position: relative;
        &.noPhoto {
            background-color: $secondary;
            padding-left: 15px;
            padding-right: 15px;
            height: 220px;
            text-align: center;    
        }    
    }
    &-info {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0.1rem .5rem;
        transform: rotate(-20deg);
        font-size: ($fzText)-2px;
        &.vendu {
            background-color: $red;
        }
    }
    &-title {
        font-size: $fzH4;
        font-weight: bold; 
        margin-bottom: 0 !important;
    }
    &-price {
        flex-basis: 100%;
        font-weight: bold;    
    }
    &-descr {
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
    }
    &-designation {
        flex-basis: 100%;
    }
    &-year {
        flex-basis: 50%;
    }
    &-km {
        flex-basis: 50%;
        text-align: right;
    }
    &-link {
        flex-basis: 100%;
        color: $colorText;    
    }
}