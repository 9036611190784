.more {
    background: transparent;
    border: 0;
    font-size: rem(16px);
    .icon-chevron-down {
        font-size: 50%;
    }
}

.btn {  
    font-family: $ffTitle;
    font-size: rem(16px);
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem 1.8rem;
    border-radius: 4px;
    box-shadow: none;
    margin: 0;
    white-space: normal; 
    transition: $transition;
    box-shadow: none;
    text-decoration: none;
    &:hover,
    &:active,
    &.active { transition: $transition; box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);}
    &:focus,
    &:active:focus,
    &.active:focus,
    &:active.focus,
    &.active.focus {
        outline: 0;
        transition: $transition;
    }
}

.btn-sm {
    font-size: rem(16px);
    font-weight: 700;
    padding: 0.6rem 0.9rem; 
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
    text-shadow: none;
}
.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
    box-shadow: none;
}

.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
    text-shadow: none;
}

.btn-link {
    color: $primary;
    padding: 0;
    background: transparent;  
    box-shadow: none;

    &:hover,
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        color: $default;
        background: transparent;  
        box-shadow: none;
        text-decoration: none;
        box-shadow: none;
    }
    &:active {
        color: $secondary;
    }
}
.btn-default {
    background: $default;
    border: 0;
    color: $main;

    &:hover {
        background: $defaultLight;
        border: 0;
        color: $main;
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: $default;
        border: 0;
        color: $defaultLight;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: $defaultDark;
        border: 0;
        color: $default;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: $defaultLight;
        border: 0;
        color: $default;
    }
}
.btn-primary {
    background: $primary;
    border: 0;
    color: $main;

    &:hover {
        background: $primaryLight;
        border: 0;
        color: $main;
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: $primaryDark;
        border: 0;
        color: $main;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: $primaryDark;
        border: 0;
        color: $main;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: $primaryLight;
        border: 0;
        color: $primary;
    }   
}

.btn-secondary {
    background: $secondary;
    border: 0;
    color: $main;

    &:hover {
        background: $secondaryLight;
        border: 0;
        color: $default;
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: $default;
        border: 0;
        color: $secondaryLight;
        box-shadow: none;
    }
    &:active,
    &.active {
        background: $secondaryLight;
        border: 0;
        color: $secondary;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: $secondaryLight;
        border: 0;
        color: $secondary;
    }
}

.btn-success {
    background: $green;
    border: 0;
    color: $main;

    &:hover {
        background: lighten($green, 20%);
        border: 0;
        color: darken($green, 20%);
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: darken($green, 20%);
        border: 0;
        color: lighten($green, 20%);
        box-shadow: none;
    }
    &:active,
    &.active {
        background: lighten($green, 20%);
        border: 0;
        color: $green;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: lighten($green, 20%);
        border: 0;
        color: $green;
    }
}

.btn-info {
    background: $blue;
    border: 0;
    color: $main;

    &:hover {
        background: lighten($blue, 20%);
        border: 0;
        color: darken($blue, 20%);
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: darken($blue, 20%);
        border: 0;
        color: lighten($blue, 20%);
        box-shadow: none;
    }
    &:active,
    &.active {
        background: lighten($blue, 20%);
        border: 0;
        color: $blue;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: lighten($blue, 20%);
        border: 0;
        color: $blue;
    }
}

.btn-warning {
    background: $orange;
    border: 0;
    color: $main;

    &:hover {
        background: lighten($orange, 20%);
        border: 0;
        color: darken($orange, 20%);
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: darken($orange, 20%);
        border: 0;
        color: lighten($orange, 20%);
        box-shadow: none;
    }
    &:active,
    &.active {
        background: lighten($orange, 20%);
        border: 0;
        color: $orange;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: lighten($orange, 20%);
        border: 0;
        color: $orange;
    }
}

.btn-danger {
    background: $red;
    border: 0;
    color: $main;

    &:hover {
        background: lighten($red, 20%);
        border: 0;
        color: darken($red, 20%);
    }
    &:focus,
    &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
        background: darken($red, 20%);
        border: 0;
        color: lighten($red, 20%);
        box-shadow: none;
    }
    &:active,
    &.active {
        background: lighten($red, 20%);
        border: 0;
        color: $red;
        box-shadow: none;
    }
    &.disabled,
    &:disabled,
    &[disabled] {
        background: lighten($red, 20%);
        border: 0;
        color: $red;
    }
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}
.button {
    @extend .btn;
    @extend .btn-default;
}
.button-primary {
    @extend .btn-primary;
}