// album dans la boucle de la liste ou widget
.galerie {
    &-album {
        margin-bottom: 2em;
        &-inner {
            
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;
            
            
            .img-fluid {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: $rounded;
            }
        }
    }
    
    &-titles {
        position: absolute;
        top: 2rem;
        left: 2rem;
        text-align: left;
        background: rgba(255,255,255,0.85);
        padding: 1rem 2rem;
    }
    &-title {
        margin: 0;
        font-size: rem(26px);
        color: $primary;
    }
    
    &-subtitle {
        font-size: rem(20px);
        color: $primary;
    }
    
    @include respond-to($semi-desktop1){
        &-titles {
            top: 1rem;
            left: 1rem;
            padding: 1rem;
        }
    }
}