// actu dans la boucle de la liste ou widget
.actualite {

    $parent: &;

    &-actu {
        &:hover {
            #{$parent}-img {
                opacity: .5;
            }
            /*
            #{$parent}-btn {
                @include hoverBouton;
            }*/
        }
    }

    &-inner {
        color: $colorText;
        position: relative;
    }

    &-title {
    }

    &-title-link {
        @extend h6;
    }

    &-entry {
        font-size: rem($fzText * .9);
        display: block;
        padding-bottom: 15px;
    }

    &-content {
        display: block;
    }

    &-imgContainer {
        background-color: $primary;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: $rounded;
        overflow: hidden;
        position: relative;
    }

    &-date  {
        font-style: normal;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        
        strong {
            background: $secondary;
            color: $main;
            font-size: rem(16px);
            padding: 0.85rem 1rem;
            border-radius: $rounded;
            position: relative;
            z-index: 1;
        }
    }
    &-nophoto {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        border-radius: $rounded;
        overflow: hidden;
        position: relative;
        .icon-camera-off {
            font-size: 1500%;
            position: relative;
            z-index: 0;
            color: $mainDark;
        }
        .actualite-date {
            display: flex;
            width: 100%;
            height: 100%;
        }
    }

    @include respond-to($mobile){
        &-imgContainer, &-nophoto {
            height: 200px;
            .img-fluid {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-width: inherit;
            }
        }
        &-nophoto {
            .icon-camera-off {
                font-size: 1000%;
            }
        }
    }@include respond-to($tablet-portrait){
        &-imgContainer, &-nophoto {
            height: 200px;
        }
        &-nophoto {
            .icon-camera-off {
                font-size: 1000%;
            }
        }
    }@include respond-to($tablet-landscape){
        &-imgContainer, &-nophoto {
            height: 190px;
        }
        &-nophoto {
            .icon-camera-off {
                font-size: 1000%;
            }
        }
    }@include respond-to($semi-desktop1){
        &-imgContainer, &-nophoto {
            height: 200px;
        }
        &-nophoto {
            .icon-camera-off {
                font-size: 1000%;
            }
        }
    }@include respond-to($semi-desktop2){
        &-imgContainer, &-nophoto {
            height: 300px;
        }
    }@include respond-to($wide-desktop){
        &-imgContainer, &-nophoto {
            height: 300px;
        }
    }

    &-img {
        transition: opacity .2s;
        &.no-photo {
            height: 300px;
        }
    }

    &-text {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &-btn {
        /*@include bouton($hover: false);*/
    }
}
