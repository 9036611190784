.reseaux {
    $parent: &;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    $parent: &;

    &__item {
        display: flex;
        margin: 0 5px;
    }
    
    &__link {
        color: #fff;    
    }
}
