.widget_contact {
    background: url(/images/background-impulsion.jpg) no-repeat center center $gray-100;
    border-radius: $rounded;
    color: $main;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .vcard {
        font-size: rem(38px);
        line-height: 1.35;
        .org {
            text-transform: uppercase;
        }

        .nickname {
            display: none;
        }

        .adr {
            .street-address, .postal-code {}

            .locality {
            }

            .country-name {
                display: none;
            }
        }

        .tel {}

        abbr {
            &.type {}

            text-decoration: none;
        }

        .tel .value {}

        .telnumber {
            a {
                text-decoration: none;
            }

            .type, .value {}
        }

        .cellnumber {
            .type, .value {}
        }

        .faxnumber {
            display: none;

            .type, .value {}
        }

        .email_container {
            margin: 20px 0;
        }

        .email_name {
            display: none;
        }

        a {
            text-decoration: underline;
            color: $main;            

            &:hover {
                text-decoration: none;
            }
        }
    }
}