@import '../modules/webauto/_autos';


.widgetAutoVIP {
    .webauto-fiche {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.webauto {
    &-image {
        border-radius: $rounded;  
        overflow: hidden;
    }
    &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}