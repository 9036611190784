.diaporama {
    &-container {
        background: #E9E9E9;
    }
    &-wrapper {

    }

    &-slide {
        flex-wrap: wrap;
    }

    &-img {

    }

    &-blockTitle {
        flex-basis: 100%;
 
    }

    &-title {
        color: $colorDiscrete;
        display: inline-block;
    }

    &-subTitle {
        font-weight: 400;
        display: inline-block;
        font-size: rem(20px);
        padding-left: 1rem;
    }

    &-buttonNext, &-buttonPrev {

    }
    
    &-pagination {
        
    }
}
