$ffTitle: "Source Sans 3", Arial, sans-serif;
$ffText: "Source Sans 3", Arial, sans-serif;
$ffItalic: "Source Sans 3", Arial, sans-serif;
$ffBold: "Source Sans 3", Arial, sans-serif;

$fzText: 18px;
$fzH1: 32px;
$fzH2: 28px;
$fzH3: 25px;
$fzH4: 21px;
$fzH5: 18px;
$fzH6: 18px;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: $red;

$main: #fff;
$mainLight: lighten( $main, 20% );
$mainDark: darken( $main, 20% );

$default: #222;
$defaultLight: lighten( $default, 20% );
$defaultDark: darken( $default, 20% );

$primary: #58215e;
$primaryLight: lighten( $primary, 20% );
$primaryDark: darken( $primary, 20% );

$secondary: #2780e3;
$secondaryLight: lighten( $secondary, 20% );
$secondaryDark: darken( $secondary, 20% );

$colorDiscrete: #05718e;

// Body
$body-bg: $main;
$bgcHeader: $main;
$bgcFooter: $default;

$colorTitle: $primary;
$colorText: $default;
$colorLink: $secondary;

$swiperThemeColor: $primary;
$swiperThemeInactiveColor: $secondary;

// Layout
$widthContainer: 1440px;
$widthContainer-lg: 1024px;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet: '(min-width: 768px) and (max-width: 1199px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';

//Divers
$transition: all, .2s, ease-out .2s;
$shadow: 0 4px 32px rgba(0,0,0,.15);
$rounded: 6px;