.product {
    padding-bottom: 3rem;
    padding-top: 15px;
    text-align: center;

    $parent: &;

    &-inner {
        transition: all, .2s;
    }

    &-link {
        color: $colorText;

        &:hover #{$parent}-img {
            opacity: .5;
        }

        > * {
            display: block;
            margin-top: .5em;
            margin-bottom: .5em;
        }
    }

    &-imgContainer {
    }

    &-img {
        border-radius: $rounded;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &-nophoto {
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        text-align: center;

        &:hover {
        }
    }

    &-about {
        display: block;
        height: 100%;
    }

    &-name {
        font-weight: 700;
        @extend h6;
    }

    &-brand {

    }

    &-price {
        color: $default;
        font-weight: 700;
    }

    &-btns {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-button {
        margin-bottom: .5em;
        margin-top: .5em;
    }

    &-text {
        margin-top: .5em;
    }

    @include respond-to($mobile){
        &-imgContainer {
            .img-fluid {
                width: 100%;
                height: 100%;
                object-fit: cover;
                max-width: inherit;
            }
        }
    }@include respond-to($phone-portrait){
        &-imgContainer {
            height: 230px;
        }
    }@include respond-to($phone-landscape){
        &-imgContainer {
            height: 200px;
        }
    }@include respond-to($tablet-portrait){
        &-imgContainer {
            height: 200px;
        }
    }@include respond-to($tablet-landscape){
        &-imgContainer{
            height: 190px;
        }
    }@include respond-to($semi-desktop1){
        &-imgContainer{
            height: 200px;
        }
    }@include respond-to($semi-desktop2){
        &-imgContainer {
            height: 300px;
        }
    }@include respond-to($wide-desktop){
        &-imgContainer {
            height: 300px;
        }
    }
}
