// Annonce dans la boucle de la liste ou widget
.recrutement {
    $parent: &;
    
    &-annonce {
    }
    
    &-title {
    }
    
    &-infos {
        
    }
    
    &-date {
    }
    
    &-description {
        @media(min-width: 1200px){
            order: -1;
        }
        
    }
    
    &-link {
    }
}