.widgetGoogle {
    h3 {
        color: $secondary;
        .img-fluid {
            display: block;
            margin: 0 auto;
        }
    }
    .widgetGoogleReviews {
        .review {
            text-align: left;
            .icon-star {
                display: block;
                width: 20px;
                height: 20px;
            }
        }
        .card {
            padding: 0;
            &-body {
                padding: 0.85rem;
            }
            &-title {
                font-size: rem(17px);
            }
            &-text {
                font-size: rem(14px);
            }
            box-shadow: none;
            border: 1px solid $gray-300;
        }
    }
    .widgetGoogleReviews-button-next, .widgetGoogleReviews-button-prev {
        position: absolute;
        top: 50%;
        z-index: 10;  
        color: #FFC107;
        font-size: 250%;
        
    }
    .widgetGoogleReviews-button-next {
        right: 0;    
    }
    .widgetGoogleReviews-button-prev {
        left: 0; 
    }
}