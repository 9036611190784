//partials
@import './front/base/appfront';
@import './front/vendors/swiper';
@import './front/home/diaporama';

// si widget actu activé
@import './front/widgets/actu-widget';
@import './front/widgets/webimmo-widget';

// si widget galerie activé
@import './front/widgets/galerie-widget';

// si widget recrutement activé
@import './front/widgets/recrutement-widget';

// si widget catalogue activé
@import './front/widgets/catalogue-widget';

// si widget webauto activé
@import './front/widgets/webauto-widget';

// si widget GoogleReviews activé
@import './front/widgets/google-review';


.home {
    &__hero {
        background: url(/images/coral_CMS-logiciel-professionnel-de-gestion-de-contenu-web.jpg) no-repeat center center $gray-200;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1 {
        color: $main;
        span {
            display: block;
            font-weight: 400;
        }
    }
    @include respond-to($phone-portrait){
        &__hero {
            padding: 2rem;
        }
        h1 {
            font-size: rem(38px);
            span {
                font-size: rem(31px);
            }
        }
    }@include respond-to($phone-landscape){
        &__hero {
            height: 280px;
            padding: 0 2rem;
        }
        h1 {
            font-size: rem(41px);
            span {
                font-size: rem(31px);
            }
        }
    }@include respond-to($tablet-portrait){
        &__hero {
            height: 370px;
            padding: 0 3rem;
        }
        h1 {
            font-size: rem(41px);
            span {
                font-size: rem(31px);
            }
        }
    }@include respond-to($tablet-landscape){
        &__hero {
            height: 370px;
        }
        h1 {
            font-size: rem(41px);
            span {
                font-size: rem(31px);
            }
        }
    }@include respond-to($semi-desktop1){
        &__hero {
            height: 400px;
        }
        h1 {
            font-size: rem(44px);
            span {
                font-size: rem(31px);
            }
        }
    }@include respond-to($semi-desktop2){
        &__hero {
            height: 500px;
        }
        h1 {
            font-size: rem(50px);
            span {
                font-size: rem(33px);
            }
        }
    }@include respond-to($wide-desktop){
        &__hero {
            height: 600px;
        }
        h1 {
            font-size: rem(50px);
            span {
                font-size: rem(33px);
            }
        }
    }
}

.edito {
    &__image {
        margin-top: -3rem;
    }
    &_2 {
        @media(min-width: 1200px){
            &__image {
                order: 1;
            }&__text {
                order: 2;
            }
        }
    }
}