#menu {
    padding: 0;
    @media (max-width: 991px){
        position: relative;
    }
}

.navbar, .navbar-light {
    padding: 0;
    .navbar-collapse {
        justify-content: flex-start;
    }
    .navbar-nav {
        width: 100%;
        margin-top: 0;
        margin-left: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        .nav-link {
            height: 100%;
            font-weight: 400;
            color: $colorDiscrete;
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 0.5rem;
            font-size: rem(20px);
            border-bottom: 1px solid transparent;
            &:hover {
                color: $default !important;
                border-bottom: 1px solid $secondaryDark;
            }
        }
        
        .active > .nav-link {
            color: $primary !important;
            border-bottom: 1px solid $primary;
        }
    }
}

#page-accueil {
    display: none;
}

/* --------- Sous-Menu / open Bootstrap 4 dropdown on hover --------- */
@media only screen and (min-width: 768px) {
    .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
    }
}

.navbar .navbar-nav {
    .dropdown-menu {
        left: -1.5rem;
        padding: 1rem 1.5rem;
        margin: 0;
        min-width: 16rem;
        color: $primary;
        background-color: $main;
        border: 1px solid $defaultLight;
        border-radius: $rounded;
        top: 80%;
        .nav-link {
            font-size: rem(17px);
            border: 0;
            &:hover {
                border: 0;
            }
        }
        .active > .nav-link {
            border: 0;
        }
    }
    .dropdown-item {
        color: $primary;
        .megamenu-content-child {
            margin: 0.8rem 0;
        }
    }
    .dropdown-item:hover, .dropdown-item:focus {
        background: transparent;
        color: $primaryLight;
    }

    .dropdown-toggle::after {
        display: inline-block;
        margin-left: 0.4em;
        font-family: "icomoon";
        content: "\e900";
        font-size: 105%;
        border: 0;
        vertical-align: middle;
    }
}

/**
 * MOBILE
 */
@media (max-width: 991px){
    .navbar {
        z-index: 10;
        position: absolute;
        width: 100%;
        top: 50px;
        left: 0;
        .navbar-collapse {
            position: relative;
            width: 100%;
            padding: 2rem;
            justify-content: center;
            background: $main; 
            border-radius: $rounded;
            box-shadow: 0 2px 8px rgba(0,0,0,0.75);
            .navbar-nav {
                align-items: flex-start;
                .active > .nav-link {
                    border-bottom: 0;
                }
            }
        }
    }
    .nav-item {
        margin: 0.5rem 0;
        display: block;
        width: 100%;
        text-align: left;
    }
    .navbar .navbar-nav {
        .dropdown-menu {
            padding: 0 0 0 1rem;
            font-size: rem(20px);
            border: 0;
            .nav-link {
                margin: 0.8rem 0;
            }
        }
    }
}

/*
 * Toggler menu mobile */
.toggler-mobile {
    //position: absolute;
    z-index: 1024;
    @media (max-width: 991px){
        margin-left: calc(50% - 25px);    
    }
    @media (min-width: 992px){
        display: none;
    }
    width: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0; 
    
    .line {
      fill: none;
      stroke: $primary;
      stroke-width: 6;
      transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .line1 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    .line2 {
      stroke-dasharray: 60 60;
      stroke-width: 6;
    }
    .line3 {
      stroke-dasharray: 60 207;
      stroke-width: 6;
    }
    /*&.opened {
        left: inherit;
        right: 10px;
    }*/
    &.opened {
        .line {
            stroke: $secondary;
        }
        .line1 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
        .line2 {
          stroke-dasharray: 1 60;
          stroke-dashoffset: -30;
          stroke-width: 6;
        }
        .line3 {
          stroke-dasharray: 90 207;
          stroke-dashoffset: -134;
          stroke-width: 6;
        }
    }
}