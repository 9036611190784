.footer {
  background: $bgcFooter;
  padding: 20px 0;
    color: $gray-400;
    &-navigationList {
        list-style: none;
    }
    &-text {
        font-size: rem(15px);
        color: $gray-400;
    }
    &__bottom {
        .border-top {
            border-color: $gray-600 !important;
        }
    }
}

.newsletter {
    h3 {
        color: $main;
    }
    .form-group {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        column-gap: 1rem;
        label {
            flex-basis: 40%;
        }
    }
}

@media(min-width: 1200px){
    .footer-text {
        text-align: right;
        
    }
}